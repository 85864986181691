
    import { mapState, mapMutations } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faBookmark as farBookmark,
    } from '@fortawesome/pro-regular-svg-icons';
    import {
        faBookmark as fasBookmark,
    } from '@fortawesome/pro-solid-svg-icons';

    import SaveSearchBtn from '@/components/SaveSearch/SaveSearchBtn';

    export default {
        name: 'SaveSearch',

        components: {
            SaveSearchModal: () => process.client ? import('@/components/SaveSearch/SaveSearchModal') : {},
            SaveSearchBtn,
            FontAwesomeIcon,
        },

        props: {
            buttonVariant: {
                type: String,
                default: 'theme-outline-light',
            },
            buttonSize: {
                type: String,
                default: 'md',
            },

            buttonClass: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                isModalOpen: false,
                isSaved: false,
                isFlyOpen: false,
                icons: {
                    farBookmark,
                    fasBookmark,
                },
            };
        },

        computed: {
            ...mapState({
                $_me: state => state.me.user,
                $_isSearchSaveModalOpen: state => state.me.isSearchSaveModalOpen,
                $_isSaveSearchFlyEnabled: state => state.search.isSavedSearchFlyEnabled,
            }),

            btnIcon() {
                return this.isSaved ? this.icons.fasBookmark : this.icons.farBookmark;
            },

            btnText() {
                return this.isSaved ? 'Alert created' : 'Save search';
            },
        },

        created() {
            if (this.$_me.id) {
                this.isModalOpen = this.$_isSearchSaveModalOpen;
            }
        },

        methods: {
            ...mapMutations('me', {
                $_OPEN_SEARCH_SAVE_MODAL: 'OPEN_SEARCH_SAVE_MODAL',
                $_CLOSE_SEARCH_SAVE_MODAL: 'CLOSE_SEARCH_SAVE_MODAL',
            }),

            onSave() {
                this.close();
                this.isSaved = true;
            },

            openModal() {
                this.isModalOpen = true;
            },

            close() {
                this.$_CLOSE_SEARCH_SAVE_MODAL();
                this.isModalOpen = false;
            },

            onScroll() {
                const searchSaveCtaBox = this.$el.getBoundingClientRect();
                this.isFlyOpen = searchSaveCtaBox.top + searchSaveCtaBox.height < 0;
            },

            beforeEnter(el) {
                el.style.translateY = '-100%';
            },

            enter(el, done) {
                this.$velocity(el, { translateY: [0, '-100%'] }, { easing: 'easeInOutQuart', duration: 900, complete: done });
            },

            beforeLeave(el) {
            },

            leave(el, done) {
                this.$velocity(el, { translateY: ['-100%', 0] }, { easing: 'easeInOutQuart', duration: 600, complete: done });
            },
        },
    };
