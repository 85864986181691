
    export default {
        name: 'SaveSearchBtn',

        props: {
            isSaved: {
                type: Boolean,
                default: false,
            },

            variant: {
                type: String,
                default: 'theme-outline-light',
            },

            size: {
                type: String,
                default: 'md',
            },

            buttonClass: {
                type: String,
                default: '',
            },
        },

        data() {
            return {};
        },

        computed: {
            classList() {
                return [
                    'save-search-btn',
                    this.getIsSavedClass,
                    this.buttonClass,
                ];
            },

            getIsSavedClass() {
                return this.isSaved ? 'is-saved' : '';
            },
        },

        methods: {
            click() {
                this.$emit('click');
            },
        },
    };
