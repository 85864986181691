
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import numeral from 'numeral';
    import debounce from 'lodash/debounce';

    export default {
        name: 'SearchbarMixin',

        data() {
            return {
                searchResults: [],
                locations: [],
                isLoading: false,
            };
        },

        computed: {
            ...mapState({
                $_appInfo: state => state.appInfo,
                $_apiMessage: state => state.apiMessage,
                $_me: state => state.me.user,
                $_search: state => state.search,
                $_activeSearchFilter: state => state.search.activeSearchFilter,
                $_jobAlertModeNew: state => state.search.jobAlertModeNew,
                $_jobAlertModeUpdate: state => state.search.jobAlertModeUpdate,
                $_jobAlertModeUpdateId: state => state.search.jobAlertModeUpdateId,
                $_payTypes: state => state.payType.payTypes,
            }),

            ...mapGetters('search', {
                $_searchFilterTags: 'getSearchFilterTags',
            }),

            isSearchFiltersLoaded() {
                const searchFiltersLength = this.$_search.searchPayload.search_filters ? Object.keys(this.$_search.searchPayload.search_filters).length : 0;
                return searchFiltersLength > 0;
            },

            searchFilters: {
                get() {
                    return this.$_search.searchPayload.search_filters;
                },
                set(value) {
                    if (this.$_search.searchPayload.search_filters && !this.$_search.searchPayload.search_filters.search_keywords) {
                        this.$store.commit('search/SET_SEARCH_FILTERS', { key: 'search_keywords', value: '', preventSearch: true });
                    }
                    if (JSON.stringify(value) === JSON.stringify(this.$_search.searchPayload.search_filters)) {
                        return;
                    }
                    value.page = 1;
                    this.$store.commit('search/SET_SEARCH_PAYLOAD', { key: 'search_filters', value });
                    if (this.$_search.searchPayload.search_filters.search_keywords !== '') {
                        this.$store.commit('search/SET_SEARCH_FILTERS', { key: 'parsed_filter', value: 0, preventSearch: true });
                    }
                    if (!this.$_activeSearchFilter) {
                        this.search();
                    }
                },
            },

            searchFilterLocation: {
                get() {
                    let location = this.$_search.searchPayload?.search_filters?.location || '';
                    if (location === '' || Array.isArray(location) || location.label === 'All Australia') {
                        location = {};
                    }
                    return location;
                },
                set(value) {
                    if (value.id) {
                        this.$store.commit('search/SET_SEARCH_FILTERS', { key: 'location', value, preventSearch: true });
                        if (!this.$_activeSearchFilter) {
                            this.search();
                        }
                    } else if (this.$_search.searchPayload.search_filters && this.$_search.searchPayload.search_filters.location && this.$_search.searchPayload.search_filters.location.id) {
                        value = {};
                        this.$store.commit('search/SET_SEARCH_FILTERS', { key: 'location', value, preventSearch: true });
                        if (!this.$_activeSearchFilter) {
                            this.search();
                        }
                    }
                },
            },

            getCategoryLabel() {
                let categoryLabel = '';
                let categoryCount = 0;
                let hasOccupation = false;
                if (this.$_search.searchPayload.search_filters && this.$_search.searchPayload.search_filters.categories) {
                    const selectedCategories = this.$_search.searchPayload.search_filters.categories;
                    selectedCategories.forEach(function(item) {
                        const occupationCount = item.occupations.length;
                        if (occupationCount <= 0) {
                            categoryCount = categoryCount + 1;
                        } else {
                            hasOccupation = true;
                            categoryCount = categoryCount + item.occupations.length;
                        }
                    });
                    if (categoryCount > 0) {
                        categoryLabel = hasOccupation ? `(in ${categoryCount} categories/occupations)` : `(in ${categoryCount} categories)`;
                    }
                }
                return categoryLabel;
            },

            workTypeFilterLabel() {
                const count = this.$_search.searchPayload.search_filters?.job_type?.length;
                if (count === 1) {
                    return this.$_search.searchPayload.search_filters?.job_type[0]?.value;
                } else if (count > 1) {
                    return `${count} work types`;
                } else {
                    return 'Work type';
                }
            },

            contractTypeFilterLabel() {
                const count = this.$_search.searchPayload.search_filters?.contract_type?.length;
                if (count === 1) {
                    return this.$_search.searchPayload.search_filters?.contract_type[0]?.value;
                } else if (count > 1) {
                    return `${count} contract types`;
                } else {
                    return 'Contract type';
                }
            },

            payRangeFilterLabel() {
                const payMinValue = this.$_search.searchPayload.search_filters?.pay_min?.value;
                const payMaxValue = this.$_search.searchPayload.search_filters?.pay_max?.value;
                const payMinLabel = `$${numeral(parseInt(payMinValue)).format('0a')}`;
                const payMaxLabel = `$${numeral(parseInt(payMaxValue)).format('0a')}`;
                const payTypeId = this.$_search.searchPayload.search_filters?.display_pay_type || 1;
                const payTypeLabel = this.$_payTypes[parseInt(payTypeId)]?.label || '';

                if (payMinValue && !payMaxValue) {
                    return `From ${payMinLabel} ${payTypeLabel}`;
                } else if (!payMinValue && payMaxValue) {
                    return `Up to ${payMaxLabel} ${payTypeLabel}`;
                } else if (payMinValue && payMaxValue) {
                    return `${payMinLabel}-${payMaxLabel} ${payTypeLabel}`;
                }
                return 'Pay range';
            },

            postedWithinFilterLabel() {
                const postedWithinDays = this.$_search.searchPayload.search_filters?.posted_within_days?.days;
                if ((postedWithinDays === 0) || !postedWithinDays) {
                    return 'Posted within';
                }
                return this.$_search.postedWithIn.find((i) => {
                    return i.days === postedWithinDays;
                }).label;
            },
        },

        watch: {
            '$_search.emitSearchFilterChanged'(newValue) {
                if (!this.$_activeSearchFilter) {
                    console.log('emit search filter changed');
                    this.$emit('searchClick');
                }
            },
        },

        methods: {
            ...mapMutations('search', {
                $_SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
                $_SET: 'SET',
                $_INIT_SEARCH_PAYLOAD: 'INIT_SEARCH_PAYLOAD',
                $_CLEAR_SEARCH_FILTER: 'CLEAR_SEARCH_FILTER',
            }),

            ...mapActions('payType', {
                $_getSalarySearchFilter: 'getSalarySearchFilter',
            }),

            onCloseSearchbarFilterModal(isVisible) {
                if (isVisible) {
                    this.activateSearchFilters();
                } else {
                    this.deactivateSearchFilters();
                }
            },

            searchJobTitle(searchQuery) {
                if (searchQuery.length === 0) {
                    this.searchResults = [];
                } else {
                    this.isLoading = true;
                    this.$store.dispatch('search/searchJobTitle', searchQuery).then((response) => {
                        this.searchResults = response.data;
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            },

            getLocations(query) {
                this.$store.dispatch('location/getLocations', { query, locale: 'AU' }).then((response) => {
                    this.locations = response.data;
                }).finally(() => {
                    this.isLoading = false;
                });
            },

            clearSelectedLocation() {
                this.$_SET(this.searchPayload, 'location', {});
            },

            // eslint-disable-next-line
            debounceSearch: debounce(function() {
                this.search();
            }, 400),

            search() {
                this.$nextTick(() => {
                    this.$emit('search');
                });
            },

            searchClick(e) {
                this.$nextTick(() => {
                    this.$emit('searchClick');
                });
                this.cleanFullScreenMode();
                this.deactivateSearchFilters();
                this.$_SET({
                    key: 'activateSearchAnimate',
                    value: false,
                });
            },

            handleJobTitleEnter() {
                this.searchClick();
            },

            handleLocationEnter() {
                this.searchClick();
            },

            submitSearch(e, callback) {
                if (this.$device.isMobile) {
                    this.$velocity(
                        document.getElementById('jobAlertCtaMobile'),
                        'scroll',
                        {
                            duration: 600,
                        });
                }
                this.searchClick(e);

                if (callback) {
                    callback();
                }
            },

            activateSearchFilters() {
                this.$store.commit('search/SET', { key: 'activeSearchFilter', value: true });
                const inputKeywordSearchEl = document.getElementById('inputKeywordSearch');
                if (inputKeywordSearchEl) {
                    inputKeywordSearchEl.focus();
                }
            },

            deactivateSearchFilters() {
                this.$store.commit('search/SET', { key: 'activeSearchFilter', value: false });
                document.body.classList.remove('body--with-fullscreen-modal');
            },

            cleanFullScreenMode() {
                if (this.$device.isMobile) {
                    this.$store.commit('search/SET', { key: 'showSearchBar', value: false });
                }
                document.body.classList.remove('body--with-fullscreen-component');
                document.body.classList.remove('c1-typeahead-multi-select--mobile-mode');
            },

            initSearchPayload() {
                this.$_INIT_SEARCH_PAYLOAD();
            },

            clearSearchFilter(key) {
                this.$_CLEAR_SEARCH_FILTER(key);
            },

            clearSearchFilterPayRange() {
                this.clearSearchFilter('pay_min');
                this.clearSearchFilter('pay_max');
                this.clearSearchFilter('display_pay_type');
            },
        },
    };
