
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    props: {
        value: {
            type: null,
            default() {
                return [];
            },
        },
        searchResults: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        filterBy: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        subLabel: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            searchData: {
                search: '',
                search_keywords: '',
                categories: [],
                parsed: {
                    job_title: [],
                    occupations: [],
                },
            },
            jobCategories: [],
            filteredResults: [],
            isSearchOpen: false,
            isLoading: false,
            isFullScreenDisplay: false,
            currentDisplay: null,
            pointer: -1,
            isCategoryOpen: false,
            isAllCategorySelected: true,
            defaultCategoryDisplayLabel: 'all categories',
            searchPlaceHolderLabel: 'Search job title or keyword',
            categoryPlaceHolderLabel: 'Type a job title, occupation or keyword',
            filteredCategories: [],
            currentCategory: null,
            maxSearchQueryLength: 3,
        };
    },

    mounted() {
        this.initSearchData();
        this.loadCategories();
        document.addEventListener('click', this.handleClickOutside);
        window.addEventListener('popstate', this.handleBackButton);
        this.focusKeywordInput();
    },

    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener('popstate', this.handleBackButton);
    },

    computed: {
        ...mapState({
            $_search: state => state.search,
        }),

        getTopResults() {
            return this.filteredResults.filter(function(item) {
                return item.display_type === 'KEYWORD_CATEGORY';
            });
        },

        getBottomResults() {
            const filteredBottomResults = this.filteredResults.filter(function(item) {
                return item.display_type === 'CATEGORY';
            });
            return filteredBottomResults.splice(0, 3);
        },

        getPlaceHolderLabel() {
            return (this.isCategoryOpen
                ? this.categoryPlaceHolderLabel
                : this.searchPlaceHolderLabel);
        },

        getCategoryDisplayLabel() {
            let categoryCount = 0;
            let hasOccupation = false;
            if (this.searchData.categories) {
                this.searchData.categories.forEach(function(item) {
                    const occupationCount = item.occupations.length;
                    if (occupationCount <= 0) {
                        categoryCount = categoryCount + 1;
                    } else {
                        hasOccupation = true;
                        categoryCount = categoryCount + item.occupations.length;
                    }
                });
            }

            if (categoryCount > 1) {
                this.isAllCategorySelected = false;
                return hasOccupation ? `<span class="c1-keyword-search__dropdown-label--highlight">${categoryCount} categories/occupations </span>`
                    : `<span class="c1-keyword-search__dropdown-label--highlight">${categoryCount} categories </span>`;
            } else if (categoryCount === 1) {
                this.isAllCategorySelected = false;
                let categoryDisplayLabel = '';
                if (this.searchData.categories[0].occupations.length) {
                    categoryDisplayLabel = this.searchData.categories[0].occupations[0].name;
                } else {
                    categoryDisplayLabel = this.searchData.categories[0].name;
                }
                return `<span class="c1-keyword-search__dropdown-label--highlight">${categoryDisplayLabel}</span>`;
            } else {
                this.isAllCategorySelected = true;
                return `<span class="c1-keyword-search__dropdown-label--strong">${
                    this.defaultCategoryDisplayLabel
                }</span>`;
            }
        },

        isCategorySelected() {
            return this.searchData.categories && this.searchData.categories.length > 0;
        },
    },

    methods: {
        ...mapActions('category', {
            $_getCategoriesWithOccupation: 'getCategoriesWithOccupation',
        }),

        ...mapMutations('search', {
            $_SET_SEARCH_PAGE_TEMPLATE: 'SET_SEARCH_PAGE_TEMPLATE',
            $_SET: 'SET',
            $_SET_SEARCH_OBJECT: 'SET_OBJECT',
        }),

        loadPreselected() {
            if (typeof this.searchData.categories !== 'undefined') {
                const preselectedCategory = this.searchData.categories;
                this.filteredCategories.forEach((jobCategory) => {
                    const categoryIndex = preselectedCategory.findIndex(
                        item => parseInt(item.id) === parseInt(jobCategory.id),
                    );
                    if (categoryIndex !== -1) {
                        this.$set(jobCategory, 'is_selected', true);
                        this.$set(jobCategory, 'is_open', true);
                        const preselectedOccupation = preselectedCategory[categoryIndex].occupations;
                        if (preselectedOccupation.length) {
                            jobCategory.occupations.forEach((jobOccupation) => {
                                const occupationIndex = preselectedOccupation.findIndex(
                                    occupationItem =>
                                        occupationItem.id === jobOccupation.id,
                                );
                                if (occupationIndex !== -1) {
                                    this.$set(jobOccupation, 'is_selected', true);
                                } else {
                                    this.$set(jobOccupation, 'is_selected', false);
                                }
                            });
                        }
                    } else {
                        this.$set(jobCategory, 'is_selected', false);
                        if (jobCategory.occupations && jobCategory.occupations.length) {
                            jobCategory.occupations.forEach((jobOccupation) => {
                                this.$set(jobOccupation, 'is_selected', false);
                            });
                        }
                    }
                });
            }
        },

        doSearch(value) {
            this.isCategoryOpen = false;
            this.searchData.search = value;
            this.searchData.search_keywords = value;
            if (value && value.length < this.maxSearchQueryLength) {
                this.clearFilterResults();
            } else {
                if (value && value.length >= this.maxSearchQueryLength) {
                    if (this.searchResults.length <= 0) {
                        this.$emit('search-change', this.getSearchQuery(value));
                    }
                }
                this.pointer = -1;
                this.filterResults();
                this.isSearchOpen = true; // init search results
            }
        },

        filterResults() {
            if (this.searchResults.length > 0) {
                const searchKeyword = this.searchData.search.toLowerCase().split();
                this.filteredResults = this.searchResults.filter((item) => {
                    const filterByKey = this.filterBy ? item[this.filterBy] : item;
                    return filterByKey.toLowerCase().includes(searchKeyword);
                });
            }
        },

        selectResult(result, index, isBottomLink) {
            this.pointer = index;
            this.searchData.search_keywords = result.search_keywords && !isBottomLink ? result.search_keywords : '';
            this.searchData.search = isBottomLink ? '' : this.getLabel(result);
            this.searchData.parsed = {};
            if (result.job_title) {
                this.searchData.parsed.job_title = [{ id: result.job_title_id, title: result.job_title, slug: result.job_title }];
            } else {
                this.searchData.parsed.job_title = [];
            }
            if (index > -1) {
                this.searchData.categories = [];
                this.filteredCategories = JSON.parse(JSON.stringify(this.jobCategories));
            }

            if (result.category_id) {
                const categoryIndex = this.filteredCategories.findIndex(
                    item => parseInt(item.id) === parseInt(result.category_id),
                );
                const jobCategory = this.filteredCategories[categoryIndex];
                this.selectCategory(jobCategory);
                if (result.occupation_id) {
                    this.searchData.parsed.occupations = [{
                        id: result.occupation_id,
                        name: this.getLabel(result),
                        category_id: result.category_id,
                    }];
                    this.searchData.search = this.getLabel(result);
                } else {
                    this.searchData.parsed.occupations = [];
                }
            }
            this.closeFullScreen();
        },

        selectFirstResult() {
            if (this.searchData.search) {
                this.selectResult(
                    {
                        category_id: null,
                        label: this.searchData.search,
                        label2: 'in All Category',
                        search_keywords: this.searchData.search,
                    },
                    -1,
                );
            }
        },

        selectCategory(jobCategory) {
            this.currentCategory = jobCategory.id;
            if (jobCategory.is_selected && !jobCategory.is_open) {
                this.$set(jobCategory, 'is_open', true);
            } else {
                this.$set(jobCategory, 'is_selected', !jobCategory.is_selected);
                this.$set(jobCategory, 'is_open', jobCategory.is_selected);
                const categoryIndex = this.searchData.categories.findIndex(
                    item => parseInt(item.id) === parseInt(jobCategory.id),
                );
                if (categoryIndex === -1) {
                    this.searchData.categories.push({
                        id: jobCategory.id,
                        name: jobCategory.name,
                        slug: jobCategory.slug,
                        occupations: [],
                    });
                } else {
                    this.searchData.categories.splice(categoryIndex, 1);
                    this.removeOccupation(jobCategory);
                }
            }
        },

        selectOccupation(jobCategory, jobOccupation) {
            this.$set(jobOccupation, 'is_selected', !jobOccupation.is_selected);

            const categoryIndex = this.searchData.categories.findIndex(
                item => parseInt(item.id) === parseInt(jobCategory.id),
            );

            const index = this.searchData.categories[categoryIndex].occupations.findIndex(item => parseInt(item.id) === parseInt(jobOccupation.id));

            if (index === -1) {
                this.searchData.categories[categoryIndex].occupations.push({
                    id: jobOccupation.id,
                    category_id: jobCategory.id,
                    name: jobOccupation.name,
                    slug: jobOccupation.slug,
                });
            } else {
                this.searchData.categories[categoryIndex].occupations.splice(index, 1);
            }
        },

        getOccupationDisplayLabel() {
            let occupationCount = 0;
            let newLabel = '';
            if (this.searchData.categories) {
                this.searchData.categories.forEach(function(item) {
                    occupationCount = occupationCount + item.occupations.length;
                    if (occupationCount > 1) {
                        newLabel = `${occupationCount} occupations selected`;
                    } else if (item.occupations.length === 1) {
                        newLabel = `${item.occupations[0].name}`;
                    }
                });
            }
            return newLabel;
        },

        isAllCategory(jobCategory) {
            if (this.searchData && this.searchData.categories) {
                const categoryIndex = this.searchData.categories.findIndex(
                    item => parseInt(item.id) === parseInt(jobCategory.id),
                );
                if (categoryIndex !== -1) {
                    return this.searchData.categories[categoryIndex].occupations.length <= 0;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },

        removeOccupation(jobCategory) {
            jobCategory.occupations.forEach(function(item) {
                item.is_selected = false;
            });
            const categoryIndex = this.searchData.categories.findIndex(
                item => parseInt(item.id) === parseInt(jobCategory.id),
            );

            if (categoryIndex !== -1) {
                const jobOccupation = this.searchData.categories[categoryIndex]
                    .occupations;
                if (jobCategory.is_selected && jobOccupation.length <= 0) {
                    this.searchData.categories.splice(categoryIndex, 1);
                    this.$set(jobCategory, 'is_selected', !jobCategory.is_selected);
                    this.$set(jobCategory, 'is_open', false);
                } else {
                    this.searchData.categories[categoryIndex].occupations = [];
                }
            }
        },

        clearSearch(enter = true) {
            this.searchData.search = '';
            this.searchData.search_keywords = '';
            this.searchData.parsed = {};
            this.clearFilterResults();
            if (JSON.stringify(this.value) !== JSON.stringify(this.searchData)) {
                this.$emit('input', this.searchData);
            }

            if (enter) {
                this.$emit('enter');
            }
        },

        clearFilterResults() {
            this.searchData.parsed.job_title = [];
            this.searchData.parsed.occupations = [];
            this.filteredResults = [];
            this.searchData.search_keywords = '';
            this.resetPointer();
            this.$emit('search-change', '');
        },

        clearCategory(emitEnter = true) {
            this.searchData.categories = [];
            this.filteredCategories = JSON.parse(JSON.stringify(this.jobCategories));
            if (!this.isFullScreenDisplay) {
                this.changeCategoryOpen(false);
            }

            if (emitEnter) {
                this.$emit('enter');
            }
        },

        getLabel(result) {
            const customLabel = this.label ? result[this.label] : result;
            return customLabel;
        },

        getSubLabel(result) {
            let customSubLabel = '';
            let categoryCount = 0;
            let hasOccupation = false;

            if (result) {
                customSubLabel = this.subLabel ? result[this.subLabel] : result;
            } else {
                if (this.searchData.categories) {
                    this.searchData.categories.forEach(function(item) {
                        const occupationCount = item.occupations.length;
                        if (occupationCount <= 0) {
                            categoryCount = categoryCount + 1;
                        } else {
                            hasOccupation = true;
                            categoryCount = categoryCount + item.occupations.length;
                        }
                    });
                }

                if (categoryCount > 1) {
                    customSubLabel = hasOccupation ? `in ${categoryCount} selected categories/occupations`
                        : `in ${categoryCount} selected categories`;
                } else if (categoryCount === 1) {
                    if (this.searchData.categories[0].occupations.length) {
                        customSubLabel = `in ${this.searchData.categories[0].occupations[0].name}`;
                    } else {
                        customSubLabel = `in ${this.searchData.categories[0].name}`;
                    }
                } else {
                    customSubLabel = 'in all categories';
                }
            }
            return customSubLabel;
        },

        optionHighlight(index) {
            return {
                'c1-keyword-search-results__item--selected': index === this.pointer,
            };
        },

        openResultsFullscreen() {
            this.isFullScreenDisplay = this.isMobile();
            this.currentDisplay = 'search';
        },

        openResults() {
            this.currentDisplay = 'search';
            this.doSearch(this.searchData.search);
            this.openSearchResults();
        },

        openSearchResults() {
            this.isSearchOpen = true;
            this.changeCategoryOpen(false);
            this.$nextTick(() => {
                this.focus();
            });
        },

        closeResults() {
            this.isSearchOpen = false;
            this.changeCategoryOpen(false);
        },

        toggleCategory() {
            this.currentDisplay = 'category';
            if (this.isFullScreenDisplay) {
                this.changeCategoryOpen(true);
            } else {
                this.changeCategoryOpen(!this.isCategoryOpen);
            }
            this.isSearchOpen = false;
        },

        toggleCategoryFullscreen() {
            this.currentDisplay = 'category';
            this.isFullScreenDisplay = this.isMobile();
            this.isSearchOpen = false;
        },

        changeCategoryOpen(value) {
            if (JSON.stringify(this.value) !== JSON.stringify(this.searchData)) {
                this.$emit('input', this.searchData);
            }
            this.isCategoryOpen = value;
        },

        async loadCategories() {
            this.jobCategories = this.$_search.categories;
            if (this.jobCategories.length <= 0) {
                this.jobCategories = await this.$_getCategoriesWithOccupation();
                this.$_SET({
                    key: 'categories',
                    value: this.jobCategories,
                });
            }
            this.filteredCategories = JSON.parse(JSON.stringify(this.jobCategories));
            this.loadPreselected();
        },

        initSearchData() {
            if (this.value) {
                this.searchData = JSON.parse(JSON.stringify(this.value));
            }
        },

        toggleJobCategory(jobCategory) {
            if (jobCategory.is_open) {
                this.$set(jobCategory, 'is_open', false);
            } else {
                this.$set(jobCategory, 'is_open', true);
            }
        },

        getSearchQuery(searchQuery) {
            return searchQuery.substring(0, this.maxSearchQueryLength);
        },

        keyInputMonitor(event) {
            const wasFullScreen = this.isFullScreenDisplay;
            if (event.key === 'Tab' || event.key === ',' || event.key === 'Enter') {
                if (this.pointer === -1) {
                    this.selectFirstResult();
                } else {
                    const selectedResult = this.getTopResults[this.pointer];
                    this.selectResult(selectedResult, this.pointer);
                }
            }
            if ((event.key === 'Tab' || event.key === 'Enter') && !wasFullScreen) {
                this.$emit('enter');
            }
        },

        handleFocusOut() {
            if (this.isFullScreenDisplay) {
                this.closeFullScreen();
            }
        },

        handleClickOutside(e) {
            const target = e ? e.target : null;
            if (!this.$el.contains(target)) {
                if (this.isSearchOpen) {
                    this.selectFirstResult();
                } else {
                    this.closeResults();
                }
            }
        },

        handleBackButton(e) {
            if (!e.target.location.href.includes('#fullscreen') && this.isFullScreenDisplay) {
                this.closeFullScreen();
            }
        },

        onArrowKeyDown() {
            if (this.pointer < this.getTopResults.length - 1) {
                this.pointer = this.pointer + 1;
            }
            this.fixScrolling();
        },

        onArrowKeyUp() {
            if (this.pointer > -1) {
                this.pointer = this.pointer - 1;
            }
            this.fixScrolling();
        },

        fixScrolling() {
            const elResult = this.$refs.c1KeywordSearchResultsTop;
            if (this.pointer > 0 && this.getTopResults.length > 0) {
                // document.getElementById(`result_item${this.pointer}`).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                // this.$refs[`result_item${this.pointer}`][0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                const elResultItemHeight = this.$refs[`option_${this.pointer}`][0].clientHeight;
                const scrollTop = elResult.scrollTop;
                const viewport = scrollTop + elResult.clientHeight;
                const elOffset = elResultItemHeight * (this.pointer + 1);
                if (elOffset < scrollTop || (elOffset + elResultItemHeight) > viewport) {
                    elResult.scrollTop = elOffset;
                }
            } else {
                elResult.scrollTop = 0;
            }
        },

        resetPointer() {
            this.pointer = -1;
            this.$refs.search_results.scrollTo(0, 0);
        },

        isMobile() {
            return window.matchMedia('(max-width: 767px)').matches;
        },

        closeFullScreen() {
            this.changeCategoryOpen(false);
            this.isSearchOpen = false;
            this.isFullScreenDisplay = false;
            this.$emit('enter');
        },

        focus() {
            if (this.isMobile()) {
                this.$refs.inputSearch.focus();
                window.scrollTo(0, 1);
            }
        },

        focusKeywordInput() {
            const inputKeywordSearchEl = document.getElementById('inputKeywordSearch');
            if (inputKeywordSearchEl && this.$device.isDesktop) {
                inputKeywordSearchEl.focus();
            }
        },
    },

    watch: {
        value: {
            // eslint-disable-next-line
            handler: function() {
                this.searchData = JSON.parse(JSON.stringify(this.value));
                this.filteredCategories = JSON.parse(JSON.stringify(this.jobCategories));
                this.loadPreselected();
            },
            deep: true,
        },

        'searchData.search'(newValue, oldValue) {
            const prevSearchQuery = (oldValue && oldValue.length >= this.maxSearchQueryLength) ? this.getSearchQuery(oldValue).toLowerCase() : '';
            const newSearchQuery = (newValue && newValue.length >= this.maxSearchQueryLength) ? this.getSearchQuery(newValue).toLowerCase() : '';
            if (newSearchQuery !== prevSearchQuery) {
                if (newSearchQuery.length >= this.maxSearchQueryLength && prevSearchQuery.length >= this.maxSearchQueryLength) {
                    this.$emit('search-change', newSearchQuery);
                }
            }
        },

        loading() {
            // Todo: run only if true and use immediate for preload
            this.filterResults();
        },

        isFullScreenDisplay(isFullscreen) {
            if (isFullscreen) {
                window.location.hash = 'fullscreen';
                this.$store.commit('search/SET', { key: 'isSearchFullscreenMode', value: true });
                if (this.currentDisplay === 'search') {
                    this.doSearch(this.searchData.search);
                    this.openSearchResults();
                } else if (this.currentDisplay === 'category') {
                    this.changeCategoryOpen(true);
                }
            } else {
                window.location.hash = '';
                this.$store.commit('search/SET', { key: 'isSearchFullscreenMode', value: false });
            }
        },
    },
};
