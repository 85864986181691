
    import {
        faSearch as falSearch,
    } from '@fortawesome/pro-light-svg-icons';
    import {
        faPlus as fasPlus,
        faTimes as fasTimes,
    } from '@fortawesome/pro-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: 'C1TypeaheadSingleSelect',

        components: {
            FontAwesomeIcon,
        },

        props: {
            value: {
                type: Object,
                default: () => {},
            },

            labelKey: {
                type: String,
                default: 'label',
            },

            valueKey: {
                type: String,
                default: 'id',
            },

            filterBy: {
                type: String,
                default: 'label',
            },

            options: {
                type: Array,
                default: () => [],
            },

            placeholder: {
                type: String,
                default: 'Type something...',
            },

            searchMinLength: {
                type: Number,
                default: 3,
            },

            hideInputIcon: {
                type: Boolean,
                default: false,
            },

            allowNew: {
                type: Boolean,
                default: false,
            },

            isInvalid: {
                type: Boolean,
                default: false,
            },

            noMobileMode: {
                type: Boolean,
                default: false,
            },

            forceMobileMode: {
                type: Boolean,
                default: false,
            },

            hideAction: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                search: '',
                selectedOption: {},
                arrowCounter: -1,
                isOpen: false,
                isMobileMode: false,
                icons: {
                    falSearch,
                    fasPlus,
                    fasTimes,
                },
                originalOptions: [],
                mutableOptions: [],
            };
        },

        computed: {
            classList() {
                return [
                    'c1-typeahead-single-select',
                    this.getIsMobileClass,
                    this.getIsInvalidClass,
                ];
            },

            getIsMobileClass() {
                return this.isMobileMode ? 'c1-typeahead-single-select--mobile position-fixed top-0 left-0 w-100 h-100 bg-white z-10050 overflow-hidden' : '';
            },

            getIsInvalidClass() {
                return this.isInvalid ? 'is-invalid' : '';
            },

            dropDownOpen() {
                return this.isOpen;
            },

            notFound() {
                if (this.search.length >= this.searchMinLength) {
                    return this.mutableOptions.length <= 0 && !this.allowNew;
                } else {
                    return false;
                }
            },

            showFirstElement() {
                return this.allowNew && this.search.length >= 3 && !this.ifValueExists(this.search);
            },
        },

        watch: {
            value: {
                // eslint-disable-next-line
                handler: function() {
                    if (this.value) {
                        this.search = this.value[this.labelKey] ? JSON.parse(JSON.stringify(this.value[this.labelKey])) : '';
                    }
                },
                deep: true,
            },

            search(value) {
                if (!value) {
                    this.emitCleanInput();
                }
            },

            options() {
                this.cloneOptions();
            },

            isMobileMode(newValue) {
                if (newValue) {
                    document.body.classList.add('body--with-fullscreen-component');
                } else {
                    document.body.classList.remove('body--with-fullscreen-component');
                }
                this.$emit('mobileMode', newValue);
            },
        },

        created() {
            this.selectedOption = JSON.parse(JSON.stringify(this.value));
            if (this.options.length) {
                this.cloneOptions();
            }
            this.search = '';
            if (this.value) {
                this.search = this.value[this.labelKey] ? JSON.parse(JSON.stringify(this.value[this.labelKey])) : '';
            }
        },

        mounted() {
            document.addEventListener('click', this.handleClickOutside);
            window.addEventListener('popstate', this.handleBackButton);
            this.resetArrowCounter();
            if (this.forceMobileMode) {
                this.activateMobileMode();
                this.$nextTick(() => {
                    this.focus();
                });
            }
        },

        destroyed() {
            document.removeEventListener('click', this.handleClickOutside);
            window.removeEventListener('popstate', this.handleBackButton);
        },

        methods: {
            setArrowCounter(number) {
                this.arrowCounter = number;
            },

            resetArrowCounter() {
                if (this.allowNew) {
                    this.arrowCounter = -1;
                } else {
                    this.arrowCounter = 0;
                }
            },

            onInput(newValue) {
                this.resetArrowCounter();
                if (newValue.length >= this.searchMinLength) {
                    if (newValue.substring(0, 3) !== this.search.substring(0, 3)) {
                        this.search = newValue;
                        this.onSearch();
                    } else {
                        this.search = newValue;
                        if (this.originalOptions.length) {
                            this.searchInternally();
                        } else if (newValue.length <= this.searchMinLength) {
                            this.onSearch();
                        } else {
                            this.close();
                        }
                    }
                } else {
                    this.close();
                    this.clearOptions();
                }
                this.search = newValue;
                if (this.search.length >= this.searchMinLength) {
                    this.isOpen = true;
                }
            },

            doSearch() {
                if (window.innerWidth < 768 && !this.noMobileMode) {
                    this.activateMobileMode();
                }
                if (this.mutableOptions.length <= 0 && (this.search.length >= this.searchMinLength)) {
                    this.onSearch();
                }
            },

            onArrowDown() {
                if (this.arrowCounter < this.mutableOptions.length - 1) {
                    this.arrowCounter += 1;
                }
                this.fixScrolling();
            },

            onArrowUp() {
                if (this.arrowCounter > -1) {
                    this.arrowCounter -= 1;
                }
                this.fixScrolling();
            },

            onEnter() {
                this.isMobileMode = false;
                const selectedOption = this.mutableOptions[this.arrowCounter];
                if (selectedOption) {
                    this.search = selectedOption[this.labelKey];
                    this.emitInput(selectedOption);
                } else if (this.allowNew) {
                    const option = this.getMatchedValueInOption(this.search);
                    if (option) {
                        this.emitInput(option);
                    } else {
                        this.selectValue(this.search);
                        this.closeDropdownAndResetArrow();
                    }
                }
            },

            keyInputMonitor(event) {
                const wasMobileMode = this.isMobileMode;
                if (event.key === 'Tab' || event.key === 'Enter') {
                    this.onEnter();
                }
                if ((event.key === 'Tab' || event.key === 'Enter') && !wasMobileMode) {
                    this.$emit('enter');
                }
            },

            emitInput(option) {
                if (option) {
                    this.selectedOption = option[this.valueKey] || this.allowNew ? JSON.parse(JSON.stringify(option)) : {};
                    this.$emit('input', this.selectedOption);
                    this.emitChange(this.selectedOption);
                } else {
                    this.selectedOption = {};
                    this.$emit('input', this.selectedOption);
                    this.emitChange(this.selectedOption);
                }
                this.closeDropdownAndResetArrow();
            },
            firstElementClick(value) {
                this.selectValue(value);
                this.close();
            },
            selectValue(value) {
                const data = {};
                data[this.labelKey] = value;
                data[this.valueKey] = null;
                this.emitInput(data);
            },

            emitCleanInput() {
                this.emitInput();
            },

            emitChange(e) {
                this.$emit('change', e);
            },

            onEsc() {
                this.closeDropdownAndResetArrow();
            },

            fixScrolling() {
                const elResults = this.$refs.c1TypeaheadSingleSelectResults;
                if (this.arrowCounter > 0 && this.mutableOptions.length > 0) {
                    /*
                    this.$refs[`option_${this.arrowCounter}`][0].scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start',
                    });
                    */
                    const elResultsItemHeight = this.$refs[`option_${this.arrowCounter}`][0].clientHeight;
                    const scrollTop = elResults.scrollTop;
                    const viewport = scrollTop + elResults.clientHeight;
                    let elOffset = 0;
                    if (this.allowNew) {
                        elOffset = elResultsItemHeight * (this.arrowCounter + 1);
                    } else {
                        elOffset = elResultsItemHeight * this.arrowCounter;
                    }
                    if (elOffset < scrollTop || (elOffset + elResultsItemHeight) > viewport) {
                        elResults.scrollTop = elOffset;
                    }
                } else {
                    elResults.scrollTop = 0;
                }
            },

            cloneOptions() {
                this.originalOptions = JSON.parse(JSON.stringify(this.options));
                this.mutableOptions = JSON.parse(JSON.stringify(this.options));
                this.searchInternally();
            },

            highlightOptions() {
                const search = this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                const query = new RegExp(search, 'i');
                this.mutableOptions.forEach((o) => {
                    this.$set(o, `${this.labelKey}_highlighted`, o[this.labelKey].replace(query, '<span>$&</span>'));
                });
            },

            getOptionLabel(option) {
                return option[`${this.labelKey}_highlighted`] || option[this.labelKey];
            },

            searchInternally() {
                const search = this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                this.mutableOptions = this.originalOptions.filter(o => o[this.filterBy].toLowerCase().search(search.toLowerCase()) >= 0);
                this.highlightOptions();
            },

            ifValueExists(option) {
                return !!this.options.find(o => o[this.labelKey].toLowerCase() === option.toLowerCase());
            },

            getMatchedValueInOption(option) {
                return this.options.find(o => o[this.labelKey].toLowerCase() === option.toLowerCase());
            },

            onSearch() {
                this.$emit('search', this.search.substring(0, 3));
            },

            closeDropdownAndResetArrow() {
                this.close();
                this.resetArrowCounter();
            },

            clearInput() {
                this.emitCleanInput();
                this.search = '';
                this.close();
                this.resetArrowCounter();
                this.focus();
            },

            clearOptions() {
                this.originalOptions = [];
                this.mutableOptions = [];
            },

            activateMobileMode() {
                window.location.hash = 'fullscreen';
                this.isMobileMode = true;
                this.$emit('fullscreen', true);
            },

            deactivateMobileMode() {
                window.location.href.substr(0, window.location.href.indexOf('#'));
                if (this.search !== '') {
                    if (!this.selectedOption[this.valueKey]) {
                        this.onEnter();
                    }
                    this.$emit('input', JSON.parse(JSON.stringify(this.selectedOption)));
                } else {
                    this.$emit('input', {});
                }
                this.isMobileMode = false;
                this.closeDropdownAndResetArrow();
                this.$emit('fullscreen', false);
            },

            open() {
                this.isOpen = true;
            },

            close() {
                this.isOpen = false;
            },

            focus() {
                this.$refs.inputSearch.focus();
            },

            handleClickOutside(e) {
                if (!this.$el.contains(e.target)) {
                    this.close();
                }
            },

            handleBackButton(e) {
                if (!e.target.location.href.includes('#fullscreen') && this.isMobileMode) {
                    this.deactivateMobileMode();
                }
            },

            handleFocusOut(e) {
                if (this.isMobileMode && e.relatedTarget === null) {
                    this.deactivateMobileMode();
                }
            },
        },
    };
